import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChannelAnalytics } from "../../redux/slices/analyticsSlice";
import { fetchCreatorStatus } from "../../redux/slices/channelSlice"; // Add this import

import { useAuth } from "@clerk/clerk-react"; // Add this import
import {
  RiBarChartLine,
  RiUserLine,
  RiVideoLine,
  RiMessage2Line,
} from "react-icons/ri";
import { useOutletContext } from "react-router-dom";
import DashboardSkeleton from "./DashboardSkeleton";
import ErrorState from "../../components/shared/ErrorState";

const CreatorStatusBanner = ({ status, onApplyClick }) => {
  if (status === "approved") return null;
  // Show either pending or apply now banner
  return (
    <div className="mb-8 bg-white border rounded-lg p-6">
      {status === "pending" ? (
        <div className="border-l-4 border-yellow-400 p-4 bg-yellow-50">
          <h2 className="text-lg font-medium text-yellow-800">
            Application Under Review
          </h2>
          <p className="text-sm text-yellow-600 mt-1">
            Your creator application is being reviewed. We'll notify you once
            it's approved.
          </p>
        </div>
      ) : (
        <div>
          <h2 className="text-lg font-medium mb-2">Start Uploading Content</h2>
          <p className="text-gray-600 mb-4">
            Ready to share your content? Apply to upload videos on Yivster.
          </p>
          <button
            onClick={onApplyClick}
            className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700"
          >
            Apply Now
          </button>
        </div>
      )}
    </div>
  );
};

const StatCard = ({ title, value, icon: Icon, trend }) => (
  <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 hover:shadow-md transition-shadow">
    <div className="flex items-center justify-between mb-4">
      <h3 className="text-sm font-medium text-gray-500">{title}</h3>
      <span className="text-gray-400">
        <Icon size={20} />
      </span>
    </div>
    <div className="flex items-baseline">
      <p className="text-2xl font-semibold text-gray-900">{value}</p>
      {trend && (
        <span
          className={`ml-2 text-sm ${
            trend > 0 ? "text-green-500" : "text-red-500"
          }`}
        >
          {trend > 0 ? "+" : ""}
          {trend}%
        </span>
      )}
    </div>
  </div>
);

const Dashboard = () => {
  const { isLoaded, isSignedIn } = useAuth();
  const { onUploadClick } = useOutletContext();
  const dispatch = useDispatch();
  const { overview, loading, error } = useSelector((state) => state.analytics);
  const { status: creatorStatus, loading: creatorLoading } = useSelector(
    (state) => state.channel.creatorStatus
  );

  // Add this in Dashboard.jsx
  useEffect(() => {
    const fetchData = async () => {
      if (isLoaded && isSignedIn) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        console.log("Fetching creator status...");
        const result = await dispatch(fetchCreatorStatus()).unwrap();
        console.log("Creator status result:", result);
      }
    };

    fetchData();
  }, [dispatch, isLoaded, isSignedIn]);

  // Dashboard.jsx
  useEffect(() => {
    const fetchData = async () => {
      if (isLoaded && isSignedIn) {
        // Small delay to ensure token is ready
        await new Promise((resolve) => setTimeout(resolve, 100));
        dispatch(fetchChannelAnalytics());
        dispatch(fetchCreatorStatus()); // Add this to fetch creator status
      }
    };

    fetchData();
  }, [dispatch, isLoaded, isSignedIn]);

  //loading states
  if (!isLoaded || loading || creatorLoading) {
    return <DashboardSkeleton />;
  }
  if (error) {
    return (
      <ErrorState
        message={error}
        onRetry={() => dispatch(fetchChannelAnalytics())}
      />
    );
  }

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Add Creator Status Banner */}
      <CreatorStatusBanner
        status={creatorStatus}
        onApplyClick={onUploadClick}
      />
      {/* Page Header */}
      <div className="mb-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Channel Dashboard
        </h1>
        <p className="mt-1 text-sm text-gray-500">
          Track your channel performance and activity
        </p>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard
          title="Total Subscribers"
          value={overview?.subscriberCount || 0}
          icon={RiUserLine}
          // trend={2.5}
        />
        <StatCard
          title="Total Views"
          value={overview?.totalViews || 0}
          icon={RiBarChartLine}
          // trend={1.8}
        />
        <StatCard
          title="Total Videos"
          value={overview?.videoCount || 0}
          icon={RiVideoLine}
        />
        <StatCard
          title="Total Comments"
          value={overview?.commentCount || 0}
          icon={RiMessage2Line}
        />
      </div>

      {/* Recent Performance Section */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-8">
        <h2 className="text-lg font-medium text-gray-900 mb-4">
          Channel Performance
        </h2>
        <div className="text-sm text-gray-500">
          {overview?.channelCreatedAt ? (
            <p>
              Your channel has received {overview.totalViews || 0} views since{" "}
              {new Date(overview.channelCreatedAt).toLocaleDateString()}
            </p>
          ) : (
            <p>Start uploading videos to see your channel performance</p>
          )}
        </div>
      </div>

      {/* Quick Actions */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <h2 className="text-lg font-medium text-gray-900 mb-4">
          Quick Actions
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <button
            onClick={onUploadClick}
            className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
          >
            <RiVideoLine className="mr-2" />
            Upload Video
          </button>
          {/*    <button className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors">
            <RiBarChartLine className="mr-2" />
            View Analytics
          </button>
          <button className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors">
            <RiMessage2Line className="mr-2" />
            Manage Comments
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
