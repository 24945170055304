import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChannelAnalytics } from "../../redux/slices/analyticsSlice";
import AnalyticsLoading from "./AnalyticsLoading";
import ErrorState from "../../components/shared/ErrorState";
import {
  RiBarChartLine,
  RiUserLine,
  RiVideoLine,
  RiTimeLine,
  RiEyeLine,
  RiPlayLine,
  RiFilter3Line,
} from "react-icons/ri";

const StatCard = ({ icon: Icon, title, value, change }) => (
  <div className="bg-gray-50 p-6 rounded-lg hover:bg-gray-100 transition-colors">
    <div className="flex items-center gap-2 mb-3">
      <Icon className="text-gray-400" size={20} />
      <h3 className="text-gray-500 text-sm font-medium">{title}</h3>
    </div>
    <div className="flex items-baseline gap-2">
      <p className="text-2xl font-bold">{value}</p>
      {change && (
        <span
          className={`text-sm ${
            change > 0 ? "text-green-500" : "text-red-500"
          }`}
        >
          {change > 0 ? "+" : ""}
          {change}%
        </span>
      )}
    </div>
  </div>
);

const Analytics = () => {
  const dispatch = useDispatch();
  const { overview, topVideos, loading, error } = useSelector(
    (state) => state.analytics
  );
  const [activeTab, setActiveTab] = useState("overview");
  const [timeRange, setTimeRange] = useState("last-28-days");

  useEffect(() => {
    dispatch(fetchChannelAnalytics());
  }, [dispatch]);

  if (loading) {
    return <AnalyticsLoading />;
  }

  if (error) {
    return (
      <ErrorState
        message={error}
        onRetry={() => dispatch(fetchChannelAnalytics())}
      />
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white border-b border-gray-200 sticky top-0 z-10">
        <div className="px-6 py-4">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-xl font-medium">Channel analytics</h1>
            {/*    <select
              value={timeRange}
              onChange={(e) => setTimeRange(e.target.value)}
              className="px-4 py-2 border border-gray-200 rounded-lg bg-white text-sm"
            >
              <option value="last-28-days">Last 28 days</option>
              <option value="last-90-days">Last 90 days</option>
              <option value="lifetime">Lifetime</option>
            </select> */}
          </div>

          {/* Tabs */}
          <div className="flex gap-8">
            <button
              className={`pb-4 px-2 relative text-sm font-medium ${
                activeTab === "overview" ? "text-blue-600" : "text-gray-600"
              }`}
              onClick={() => setActiveTab("overview")}
            >
              Overview
              {activeTab === "overview" && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600"></div>
              )}
            </button>
            {/*        <button className="pb-4 px-2 text-sm text-gray-400 cursor-not-allowed">
              Content
            </button>
            <button className="pb-4 px-2 text-sm text-gray-400 cursor-not-allowed">
              Audience
            </button>
            <button className="pb-4 px-2 text-sm text-gray-400 cursor-not-allowed">
              Inspiration
            </button> */}
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="p-6">
        {/* Overview Stats */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-lg font-medium">Your channel performance</h2>
            {/*   <button className="flex items-center gap-2 px-3 py-1.5 text-sm border border-gray-200 rounded-lg hover:bg-gray-50">
              <RiFilter3Line size={18} />
              COMPARE TO
            </button> */}
          </div>

          <p className="text-gray-600 mb-6">
            {overview?.channelCreatedAt
              ? `Performance since ${new Date(
                  overview.channelCreatedAt
                ).toLocaleDateString()}`
              : "Start uploading videos to see your channel performance"}
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <StatCard
              icon={RiEyeLine}
              title="Views"
              value={overview?.totalViews?.toLocaleString() || "0"}
              // change={2.5}
            />
            <StatCard
              icon={RiUserLine}
              title="Subscribers"
              value={overview?.subscriberCount?.toLocaleString() || "0"}
              // change={1.8}
            />
            <StatCard
              icon={RiVideoLine}
              title="Videos"
              value={overview?.videoCount?.toLocaleString() || "0"}
            />
          </div>
        </div>

        {/* Top Videos */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-lg font-medium">Your top content</h2>
            <p className="text-sm text-gray-500 mt-1">Based on views</p>
          </div>

          <div className="divide-y divide-gray-200">
            {topVideos?.map((video, index) => (
              <div
                key={video.id}
                className="p-4 hover:bg-gray-50 transition-colors"
              >
                <div className="flex items-center gap-6">
                  <span className="text-gray-400 font-medium w-6">
                    {index + 1}
                  </span>
                  <div className="relative w-32 h-20 flex-shrink-0">
                    <img
                      src={video.thumbnailUrl}
                      alt={video.title}
                      className="w-full h-full object-cover rounded"
                    />
                    {video.duration && (
                      <span className="absolute bottom-1 right-1 bg-black bg-opacity-75 text-white text-xs px-1 rounded">
                        {video.duration}
                      </span>
                    )}
                  </div>
                  <div className="flex-1 min-w-0">
                    <h3 className="font-medium line-clamp-2">{video.title}</h3>
                    <p className="text-sm text-gray-500 mt-1">
                      Published {new Date(video.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="font-medium">
                      {video.views.toLocaleString()} views
                    </p>
                    <p className="text-sm text-gray-500">Last 28 days</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
