// src/Pages/Content/pages/VideoComments.jsx
import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { RiArrowLeftLine as ArrowLeft } from "react-icons/ri";
import LoadingSpinner from "../../../components/shared/LoadingSpinner";
import api from "../../../api/axios";

const VideoComments = () => {
  const { videoId } = useParams();
  const [comments, setComments] = useState([]);
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch video details and comments in parallel
        const [videoRes, commentsRes] = await Promise.all([
          api.get(`/api/studio/videos/${videoId}`),
          api.get(`/api/studio/videos/${videoId}/comments`),
        ]);

        setVideoData(videoRes.data);
        setComments(commentsRes.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.response?.data?.message || "Error fetching comments");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [videoId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <div className="p-6 text-red-500">Error: {error}</div>;
  }

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex items-center gap-4 mb-6">
        <Link to="/content" className="p-2 hover:bg-gray-100 rounded-full">
          <ArrowLeft size={20} />
        </Link>
        <div>
          <h1 className="text-2xl font-semibold">Video Comments</h1>
          <p className="text-gray-600">{videoData?.title}</p>
        </div>
      </div>

      {/* Comments List */}
      <div className="bg-white rounded-lg shadow-sm">
        {comments.length === 0 ? (
          <div className="p-6 text-center text-gray-500">No comments yet</div>
        ) : (
          <div className="divide-y">
            {comments.map((comment) => (
              <div key={comment._id} className="p-4 hover:bg-gray-50">
                <div className="flex items-start gap-3">
                  <img
                    src={comment.userId?.imageUrl || "/default-avatar.png"}
                    alt={comment.userId?.username}
                    className="w-10 h-10 rounded-full"
                  />
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <span className="font-medium">
                        {comment.userId?.username || "Anonymous"}
                      </span>
                      <span className="text-sm text-gray-500">
                        {new Date(comment.createdAt).toLocaleDateString()}
                      </span>
                    </div>
                    <p className="mt-1 text-gray-800">{comment.content}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoComments;
