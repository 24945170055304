import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchVideos } from "../../../redux/slices/videoSlice";
import EditVideoModal from "../components/EditVideoModal";
import DeleteVideoModal from "../components/DeleteVideoModal";
import VideoList from "../components/VideoList";
import ContentSkeleton from "../components/ContentSkeleton";
import ErrorState from "../../../components/shared/ErrorAlert";
import {
  RiUploadCloud2Line,
  RiSearchLine,
  RiFilter3Line,
} from "react-icons/ri";

const Content = () => {
  const dispatch = useDispatch();
  const {
    items: videos,
    loading,
    error,
  } = useSelector((state) => state.videos);
  console.log("Redux state:", { videos, loading, error });
  console.log("VideoList props:", videos);

  const [activeTab, setActiveTab] = useState("videos");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    dispatch(fetchVideos());
  }, [dispatch]);

  const handleEditClick = (video) => {
    setSelectedVideo(video);
    setEditModalOpen(true);
  };

  const handleDeleteClick = (video) => {
    setSelectedVideo(video);
    setDeleteModalOpen(true);
  };

  if (loading) {
    return <ContentSkeleton />;
  }

  if (error) {
    return (
      <ErrorState message={error} onRetry={() => dispatch(fetchVideos())} />
    );
  }

  return (
    <div className="bg-white min-h-screen">
      {/* Header */}
      <div className="border-b border-gray-200">
        <div className="px-6 py-4">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-xl font-medium text-gray-900">
              Channel content
            </h1>
            {/*  <button className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
              <RiUploadCloud2Line className="mr-2" />
              CREATE
            </button> */}
          </div>

          {/* Tabs */}
          <div className="flex gap-8">
            <button
              className={`pb-4 px-2 relative ${
                activeTab === "videos"
                  ? "text-blue-600 font-medium"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveTab("videos")}
            >
              Videos
              {activeTab === "videos" && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600"></div>
              )}
            </button>
            {/*    <button
              className="pb-4 px-2 text-gray-400 cursor-not-allowed"
              disabled
            >
              Shorts
            </button>
            <button
              className="pb-4 px-2 text-gray-400 cursor-not-allowed"
              disabled
            >
              Live
            </button> */}
          </div>
        </div>

        {/* Search and Filters */}
        {/*  <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
          <div className="flex gap-4">
            <div className="relative flex-1 max-w-md">
              <input
                type="text"
                placeholder="Search across your videos"
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <RiSearchLine className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <button className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-100 flex items-center">
              <RiFilter3Line className="mr-2" />
              FILTER
            </button>
          </div>
        </div> */}
      </div>

      {/* Video List */}
      <div className="p-6">
        <VideoList
          videos={videos}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
        />
      </div>

      {/* Modals */}
      <EditVideoModal
        video={selectedVideo}
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSave={(updatedData) => {
          console.log("Updating video:", updatedData);
          setEditModalOpen(false);
        }}
      />

      <DeleteVideoModal
        video={selectedVideo}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={(videoId) => {
          console.log("Deleting video:", videoId);
          setDeleteModalOpen(false);
        }}
      />
    </div>
  );
};

export default Content;
