// src/components/common/Toast.jsx
import React, { useEffect } from "react";
import { RiCheckLine, RiErrorWarningLine, RiCloseLine } from "react-icons/ri";

const Toast = ({ message, type = "success", onClose, duration = 3000 }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const icons = {
    success: <RiCheckLine className="text-green-500" size={20} />,
    error: <RiErrorWarningLine className="text-red-500" size={20} />,
  };

  return (
    <div className="fixed top-4 right-4 z-50">
      <div className="bg-white shadow-lg rounded-lg p-4 flex items-center gap-3 min-w-[300px]">
        {icons[type]}
        <p className="flex-1">{message}</p>
        <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded">
          <RiCloseLine size={20} />
        </button>
      </div>
    </div>
  );
};

export default Toast;
