import React, { useState, useRef } from "react";
import { useAuth } from "@clerk/clerk-react";
import { useToast } from "../providers/ToastProvider";
import {
  Upload,
  X,
  Image as ImageIcon,
  Video,
  CheckCircle2,
} from "lucide-react";

import axios from "axios";

const UploadVideoModal = ({ isOpen, onClose }) => {
  const { getToken } = useAuth();
  const { showToast } = useToast();
  const fileInputRef = useRef(null);
  const thumbnailInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("idle"); // 'idle' | 'uploading' | 'processing' | 'success' | 'error'
  const [videoDetails, setVideoDetails] = useState({
    title: "",
    description: "",
    tags: "",
  });
  const [thumbnailPreview, setThumbnailPreview] = useState(null);

  const getVideoDuration = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const handleFileSelect = (file) => {
    if (file && file.type.startsWith("video/")) {
      setSelectedFile(file);
    } else {
      showToast("Please select a valid video file", "error");
    }
  };

  const handleThumbnailSelect = async (e) => {
    const file = e.target.files[0];

    try {
      await validateThumbnail(file);
      setThumbnailFile(file);

      // Create preview
      const reader = new FileReader();
      reader.onload = (e) => {
        const previewUrl = e.target.result;
        setThumbnailPreview(previewUrl);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Thumbnail validation error:", error);
      showToast(error.message, "error");
    }
  };

  const validateThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      // Validate file type and size first
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        reject(new Error("Thumbnail must be JPG or PNG"));
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        reject(new Error("Thumbnail must be less than 2 MB"));
        return;
      }

      // Create image to check dimensions
      const img = new Image();
      img.onload = () => {
        console.log("Image loaded. Dimensions:", img.width, "x", img.height);

        // Check width (min 640px, ideal 1280px)
        if (img.width < 640) {
          reject(new Error("Thumbnail width must be at least 640 pixels"));
          return;
        }

        // Strict 16:9 aspect ratio check
        const aspectRatio = img.width / img.height;
        const idealAspectRatio = 16 / 9;
        const tolerance = 0.1; // Allow slight variations

        console.log("Aspect ratio:", aspectRatio, "Ideal:", idealAspectRatio);

        if (Math.abs(aspectRatio - idealAspectRatio) > tolerance) {
          reject(
            new Error(
              `Thumbnail must have a 16:9 aspect ratio. Current ratio is ${aspectRatio.toFixed(
                2
              )}`
            )
          );
          return;
        }

        // NEW: Recommended Resolution Check
        const recommendedWidth = 1280;
        const recommendedHeight = 720;
        const minAcceptableWidth = 640;
        const minAcceptableHeight = 360;

        // Warning for less than ideal, error for below minimum
        if (
          img.width < minAcceptableWidth ||
          img.height < minAcceptableHeight
        ) {
          reject(
            new Error(
              `Thumbnail resolution too low. Recommended: ${recommendedWidth}x${recommendedHeight}, Minimum: ${minAcceptableWidth}x${minAcceptableHeight}`
            )
          );
          return;
        }

        // Optional: Basic image quality hint
        const fileKB = file.size / 1024;
        const pixelCount = img.width * img.height;

        // Basic heuristics for potential low-quality images
        if (fileKB < 50 || pixelCount < 300000) {
          console.warn("Potential low-quality image detected");
        }

        resolve(true);
      };

      img.onerror = () => {
        reject(new Error("Unable to load image"));
      };

      img.src = URL.createObjectURL(file);
    });
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    handleFileSelect(file);
  };

  /*   const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    setUploadStatus("uploading");
    setUploadProgress(0);

    // Validate all required fields
    if (!selectedFile || !thumbnailFile) {
      showToast("Please select both a video and a thumbnail file", "error");
      setIsUploading(false);
      setUploadStatus("error");
      return;
    }

    if (!videoDetails.title.trim()) {
      showToast("Please enter a title", "error");
      setIsUploading(false);
      setUploadStatus("error");
      return;
    }

    if (!videoDetails.description.trim()) {
      showToast("Please enter a description", "error");
      setIsUploading(false);
      setUploadStatus("error");
      return;
    }

    if (!videoDetails.tags.trim()) {
      showToast("Please add at least one tag", "error");
      setIsUploading(false);
      setUploadStatus("error");
      return;
    }

    try {
      const duration = await getVideoDuration(selectedFile);

      // Format tags before creating FormData
      const formattedTags = videoDetails.tags
        .split(",")
        .map((tag) => tag.trim().toLowerCase())
        .filter((tag) => tag.length > 0)
        .filter((tag, index, self) => self.indexOf(tag) === index);

      // Additional validation for tags after formatting
      if (formattedTags.length === 0) {
        showToast("Please add at least one valid tag", "error");
        setIsUploading(false);
        setUploadStatus("error");
        return;
      }

      const formData = new FormData();
      formData.append("video", selectedFile);
      formData.append("thumbnail", thumbnailFile);
      formData.append("title", videoDetails.title.trim());
      formData.append("description", videoDetails.description.trim());
      formData.append("duration", duration);
      formData.append("tags", formattedTags);

      const token = await getToken();

      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded * 100) / event.total);
          setUploadProgress(progress);
        }
      });

      xhr.upload.addEventListener("load", () => {
        setUploadStatus("processing");
        setUploadProgress(100);
      });

      xhr.addEventListener("load", () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          setUploadStatus("success");
          showToast("🎉 Video uploaded successfully!", "success");

          // Show success state briefly before closing
          setTimeout(() => {
            setIsUploading(false);
            setUploadStatus("idle");
            onClose();
            // Reset form state
            setSelectedFile(null);
            setThumbnailFile(null);
            setThumbnailPreview(null);
            setVideoDetails({
              title: "",
              description: "",
              tags: "",
            });
          }, 2000); // Show success message for 2 seconds
        } else {
          throw new Error("Upload failed");
        }
      });

      xhr.addEventListener("error", () => {
        throw new Error("Upload failed");
      });

      xhr.open("POST", "http://localhost:5000/api/videos/upload");
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.send(formData);
    } catch (error) {
      console.error("Upload error:", error);
      showToast("Failed to upload video", "error");
      setUploadStatus("error");
    }
  }; */

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    setUploadStatus("uploading");
    setUploadProgress(0);

    if (!selectedFile || !thumbnailFile) {
      showToast("Please select both a video and a thumbnail file", "error");
      setIsUploading(false);
      setUploadStatus("error");
      return;
    }

    try {
      const duration = await getVideoDuration(selectedFile);

      // Format tags
      const formattedTags = videoDetails.tags
        .split(",")
        .map((tag) => tag.trim().toLowerCase())
        .filter((tag) => tag.length > 0)
        .filter((tag, index, self) => self.indexOf(tag) === index);

      const formData = new FormData();
      formData.append("video", selectedFile);
      formData.append("thumbnail", thumbnailFile);
      formData.append("title", videoDetails.title.trim());
      formData.append("description", videoDetails.description.trim());
      formData.append("duration", duration);
      formData.append("tags", formattedTags);

      const token = await getToken();

      const response = await axios.post(
        "http://localhost:5000/api/videos/upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
            if (percentCompleted === 100) {
              setUploadStatus("processing");
            }
          },
        }
      );

      if (response.status === 201) {
        setUploadStatus("success");
        showToast("🎉 Video uploaded successfully!", "success");

        setTimeout(() => {
          setIsUploading(false);
          setUploadStatus("idle");
          onClose();
          setSelectedFile(null);
          setThumbnailFile(null);
          setThumbnailPreview(null);
          setVideoDetails({
            title: "",
            description: "",
            tags: "",
          });
        }, 2000);
      }
    } catch (error) {
      console.error("Upload error:", error);
      showToast("Failed to upload video", "error");
      setUploadStatus("error");
      setIsUploading(false);
    }
  };

  const UploadStatus = () => {
    if (uploadStatus === "idle") return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg p-6 w-96 text-center">
          {uploadStatus === "success" ? (
            <div className="space-y-4">
              <CheckCircle2 className="w-16 h-16 text-green-500 mx-auto animate-bounce" />
              <p className="text-xl font-medium text-gray-800">
                Upload Complete!
              </p>
              <p className="text-sm text-gray-600">
                Your video will be available shortly
              </p>
            </div>
          ) : (
            <div className="space-y-4">
              <p className="text-lg font-medium mb-4">
                {uploadStatus === "processing"
                  ? "Uploading to Yivster..."
                  : "Processing..."}
              </p>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className="bg-purple-600 h-2 rounded-full transition-all duration-300"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-600">
                {uploadProgress}% Complete
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center">
        <div className="bg-white rounded-lg w-full max-w-3xl shadow-xl mx-4">
          {/* Header */}
          <div className="flex justify-between items-center p-6 border-b">
            <h2 className="text-xl font-medium">Upload video</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <X size={20} />
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Upload Section */}
              <div className="space-y-6">
                <div
                  className={`border-2 border-dashed rounded-lg ${
                    dragActive
                      ? "border-blue-500 bg-blue-50"
                      : "border-gray-300"
                  }`}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <div className="p-8 text-center">
                    <input
                      ref={fileInputRef}
                      type="file"
                      className="hidden"
                      accept="video/*"
                      onChange={(e) => handleFileSelect(e.target.files[0])}
                    />
                    <Video className="mx-auto h-12 w-12 text-gray-400 mb-4" />
                    {selectedFile ? (
                      <div>
                        <p className="text-sm font-medium">
                          {selectedFile.name}
                        </p>
                        <button
                          type="button"
                          onClick={() => fileInputRef.current?.click()}
                          className="mt-2 text-blue-600 hover:text-blue-700 text-sm"
                        >
                          Change video
                        </button>
                      </div>
                    ) : (
                      <>
                        <p className="text-gray-600 mb-2">
                          Drag and drop your video here
                        </p>
                        <button
                          type="button"
                          onClick={() => fileInputRef.current?.click()}
                          className="text-blue-600 hover:text-blue-700"
                        >
                          or choose file
                        </button>
                      </>
                    )}
                  </div>
                </div>

                {/* Thumbnail Upload */}
                <div className="border rounded-lg p-4">
                  <div className="flex items-center gap-4">
                    <ImageIcon className="text-gray-400" />
                    <div className="flex-1">
                      <p className="font-medium text-sm mb-1">Thumbnail</p>
                      <input
                        ref={thumbnailInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleThumbnailSelect}
                        className="hidden"
                      />
                      <button
                        type="button"
                        onClick={() => thumbnailInputRef.current?.click()}
                        className="text-blue-600 hover:text-blue-700 text-sm"
                      >
                        {thumbnailFile
                          ? "Change thumbnail"
                          : "Upload thumbnail"}
                      </button>
                    </div>
                  </div>

                  {/* Thumbnail Preview */}
                  {thumbnailPreview && (
                    <div className="mt-4">
                      <img
                        src={thumbnailPreview}
                        alt="Thumbnail Preview"
                        className="w-full h-40 object-cover rounded-lg"
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Details Section */}
              {/* Details Section */}
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Title <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={videoDetails.title}
                    onChange={(e) =>
                      setVideoDetails({
                        ...videoDetails,
                        title: e.target.value,
                      })
                    }
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                    minLength="3"
                    maxLength="100"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Description <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    value={videoDetails.description}
                    onChange={(e) =>
                      setVideoDetails({
                        ...videoDetails,
                        description: e.target.value,
                      })
                    }
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
                    required
                    minLength="10"
                    maxLength="5000"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Tags <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={videoDetails.tags}
                    onChange={(e) =>
                      setVideoDetails({ ...videoDetails, tags: e.target.value })
                    }
                    placeholder="gaming, tutorial, javascript"
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                  <p className="text-sm text-gray-500 mt-1">
                    Separate tags with commas. At least one tag is required.
                  </p>
                </div>
              </div>
            </div>

            {/* Footer */}
            <div className="flex justify-end gap-3 p-6 border-t bg-gray-50">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                disabled={isUploading}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isUploading || !selectedFile || !thumbnailFile}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
              >
                {isUploading ? (
                  <>
                    <Upload className="animate-spin" size={18} />
                    Uploading...
                  </>
                ) : (
                  "Upload"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <UploadStatus />
    </>
  );
};

export default UploadVideoModal;
