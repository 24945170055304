import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ClerkProvider, SignIn, SignUp } from "@clerk/clerk-react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import ProtectedRoute from "./components/ProtectedRoute";
import Layout from "./components/layout/Layout";

// Import your pages
import Dashboard from "./Pages/Dashboard/Dashboard";
import Content from "./Pages/Content/pages";
import Analytics from "./Pages/Analytics/Analytics";
import Monetization from "./Pages/Monetization/Monetization";
import Community from "./Pages/Community";
import Customization from "./Pages/Customization/Customization";
import Settings from "./Pages/Settings";
import VideoStats from "./Pages/VideoStats";
import VideoAnalytics from "./Pages/Content/pages/VideoAnalytics";
import VideoComments from "./Pages/Content/pages/VideoComments";

import { ToastProvider } from "./components/providers/ToastProvider";

console.log("All env vars:", process.env);
console.log("Specific key:", process.env.REACT_APP_CLERK_PUBLISHABLE_KEY);

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!clerkPubKey) {
  console.error("Key is missing or undefined");
  throw new Error("Missing Clerk Publishable Key");
}

function App() {
  return (
    <Provider store={store}>
      <ClerkProvider publishableKey={clerkPubKey}>
        <ToastProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path="/sign-in/*"
                element={<SignIn routing="path" path="/sign-in" />}
              />
              <Route
                path="/sign-up/*"
                element={<SignUp routing="path" path="/sign-up" />}
              />

              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Layout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<Navigate to="/dashboard" replace />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="content" element={<Content />} />

                <Route path="analytics" element={<Analytics />} />
                <Route path="monetization" element={<Monetization />} />
                <Route path="community" element={<Community />} />
                <Route path="customization" element={<Customization />} />
                {/* <Route path="settings" element={<Settings />} /> */}
                <Route path="video-stats" element={<VideoStats />} />
                <Route
                  path="video-stats/:videoId"
                  element={<VideoAnalytics />}
                />
                <Route
                  path="/video-comments/:videoId"
                  element={<VideoComments />}
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </ToastProvider>
      </ClerkProvider>
    </Provider>
  );
}

export default App;
