import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useToast } from "../../../components/providers/ToastProvider";
import {
  RiMoreFill,
  RiEdit2Line,
  RiBarChart2Line,
  RiMessage2Line,
  RiEyeLine,
  RiDeleteBin2Line,
  RiLink,
} from "react-icons/ri";

const VideoList = ({ videos = [], onEditClick, onDeleteClick }) => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { showToast } = useToast();

  const handleCopyLink = async (videoId) => {
    try {
      await navigator.clipboard.writeText(
        `https://yivster.com/video/${videoId}`
      );
      showToast("Link copied to clipboard", "success");
    } catch (err) {
      showToast("Failed to copy link", "error");
    }
    setActiveDropdown(null);
  };

  // Click outside handler
  const handleClickOutside = () => {
    if (activeDropdown) {
      setActiveDropdown(null);
    }
  };

  if (!videos || videos.length === 0) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-500">No videos found</p>
      </div>
    );
  }

  return (
    <div className="bg-white border border-gray-200 rounded-lg divide-y">
      {/* Table Header */}
      <div className="grid grid-cols-12 gap-4 p-4 bg-gray-50 text-sm font-medium text-gray-500">
        <div className="col-span-6">Videos</div>
        <div className="col-span-2">Visibility</div>
        <div className="col-span-2">Date</div>
        <div className="col-span-1">Views</div>
        <div className="col-span-1 text-center">Actions</div>
      </div>

      {/* Video Items */}
      {videos.map((video) => (
        <div
          key={video._id}
          className="grid grid-cols-12 gap-4 p-4 hover:bg-gray-50"
        >
          {/* Thumbnail and Title */}
          <div className="col-span-6 flex gap-4">
            <div className="relative w-32 h-20 flex-shrink-0">
              <img
                src={video.thumbnailUrl}
                alt={video.title}
                className="w-full h-full object-cover rounded"
              />
              <span className="absolute bottom-1 right-1 bg-black bg-opacity-75 text-white text-xs px-1 rounded">
                {video.duration}
              </span>
            </div>
            <div className="flex-1 min-w-0">
              <h3 className="font-medium text-sm truncate">{video.title}</h3>
              <p className="text-sm text-gray-500 mt-1 line-clamp-2">
                {video.description}
              </p>
            </div>
          </div>

          {/* Visibility */}
          <div className="col-span-2 flex items-center text-sm">
            {video.visibility || "Public"}
          </div>

          {/* Date */}
          <div className="col-span-2 flex items-center text-sm">
            {new Date(video.createdAt).toLocaleDateString()}
          </div>

          {/* Views */}
          <div className="col-span-1 flex items-center text-sm">
            {video.views?.toLocaleString() || 0}
          </div>

          {/* Actions */}
          <div className="col-span-1 flex justify-center items-center relative">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setActiveDropdown(
                  activeDropdown === video._id ? null : video._id
                );
              }}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <RiMoreFill size={20} />
            </button>

            {/* Dropdown Menu */}
            {activeDropdown === video._id && (
              <>
                <div
                  className="fixed inset-0 z-40"
                  onClick={handleClickOutside}
                />
                <div className="absolute right-0 top-10 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-50">
                  <Link
                    to={`/video-stats/${video._id}`}
                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => setActiveDropdown(null)}
                  >
                    <RiBarChart2Line className="mr-3" />
                    Analytics
                  </Link>
                  <button
                    onClick={() => {
                      onEditClick(video);
                      setActiveDropdown(null);
                    }}
                    className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                  >
                    <RiEdit2Line className="mr-3" />
                    Edit
                  </button>
                  <Link
                    to={`/video-comments/${video._id}`}
                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => setActiveDropdown(null)}
                  >
                    <RiMessage2Line className="mr-3" />
                    Comments
                  </Link>
                  <button
                    onClick={() => handleCopyLink(video._id)}
                    className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                  >
                    <RiLink className="mr-3" />
                    Copy link
                  </button>
                  <Link
                    to={`/video/${video._id}`}
                    target="_blank"
                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => setActiveDropdown(null)}
                  >
                    <RiEyeLine className="mr-3" />
                    View on Yivster
                  </Link>
                  <button
                    onClick={() => {
                      onDeleteClick(video);
                      setActiveDropdown(null);
                    }}
                    className="w-full flex items-center px-4 py-2 text-sm text-red-600 hover:bg-gray-50 border-t border-gray-100"
                  >
                    <RiDeleteBin2Line className="mr-3" />
                    Delete
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default VideoList;
