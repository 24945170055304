// src/redux/slices/commentsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/axios";

export const fetchAllComments = createAsyncThunk(
  "comments/fetchAllComments",
  async (_, { rejectWithValue }) => {
    try {
      console.log("Fetching all channel comments...");
      const response = await api.get("/studio/comments");
      console.log("Comments Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching comments:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch comments"
      );
    }
  }
);

const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    comments: [],
    loading: false,
    error: null,
    filter: "all", // 'all', 'published', 'held'
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    clearComments: (state) => {
      state.comments = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllComments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllComments.fulfilled, (state, action) => {
        state.loading = false;
        state.comments = action.payload;
      })
      .addCase(fetchAllComments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFilter, clearComments } = commentsSlice.actions;
export default commentsSlice.reducer;
