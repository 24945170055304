const ContentSkeleton = () => (
  <div className="bg-white min-h-screen">
    {/* Header */}
    <div className="border-b border-gray-200">
      <div className="px-6 py-4">
        <div className="flex justify-between items-center mb-6">
          <div className="h-6 bg-gray-200 rounded w-36 animate-pulse"></div>
        </div>
        <div className="flex gap-8">
          <div className="h-4 bg-gray-200 rounded w-24 animate-pulse"></div>
        </div>
      </div>
    </div>

    {/* Video List */}
    <div className="p-6 animate-pulse">
      {[...Array(4)].map((_, i) => (
        <div
          key={i}
          className="mb-4 bg-white rounded-lg border border-gray-200 p-4"
        >
          <div className="flex items-center space-x-4">
            <div className="w-40 h-24 bg-gray-200 rounded"></div>
            <div className="flex-1">
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            </div>
            <div className="w-24">
              <div className="h-8 bg-gray-200 rounded mb-2"></div>
              <div className="h-8 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default ContentSkeleton;
