import { configureStore } from "@reduxjs/toolkit";
import channelReducer from "./slices/channelSlice";
import videoReducer from "./slices/videoSlice";
import analyticsReducer from "./slices/analyticsSlice";
import settingsReducer from "./slices/settingsSlice";
import commentsReducer from "./slices/commentsSlice";
import monetizationReducer from "./slices/monetizationSlice";
import authReducer from "./slices/authSlice";

import { comment } from "postcss";

export const store = configureStore({
  reducer: {
    channel: channelReducer,
    videos: videoReducer,
    analytics: analyticsReducer,
    settings: settingsReducer,
    comments: commentsReducer,
    monetization: monetizationReducer,
    auth: authReducer,
  },
});
