import React from "react";
import {
  RiUploadCloud2Line,
  RiBarChartLine,
  RiUserLine,
  RiVideoLine,
  RiMessage2Line,
} from "react-icons/ri";
import { Upload } from "lucide-react";

// Customization Page Loading
const CustomizationSkeleton = () => (
  <div className="min-h-screen bg-gray-50 text-gray-800 p-6 animate-pulse">
    <div className="max-w-4xl mx-auto">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div className="h-8 bg-gray-200 rounded w-48"></div>
        <div className="flex space-x-4">
          <div className="w-24 h-10 bg-gray-200 rounded"></div>
          <div className="w-24 h-10 bg-gray-200 rounded"></div>
        </div>
      </div>

      <div className="space-y-8">
        {/* Banner Section */}
        <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm">
          <div className="flex">
            <div className="w-64 h-36 bg-gray-200 rounded"></div>
            <div className="flex-1 ml-8">
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            </div>
          </div>
        </div>

        {/* Profile Section */}
        <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm">
          <div className="flex">
            <div className="w-32 h-32 bg-gray-200 rounded-full"></div>
            <div className="flex-1 ml-8">
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            </div>
          </div>
        </div>

        {/* Basic Info Section */}
        <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm space-y-6">
          {[...Array(3)].map((_, i) => (
            <div key={i}>
              <div className="h-4 bg-gray-200 rounded w-24 mb-2"></div>
              <div className="h-10 bg-gray-200 rounded w-full"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default CustomizationSkeleton;
