import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteVideo } from "../../../redux/slices/videoSlice";
import { useToast } from "../../../components/providers/ToastProvider";
import { RiDeleteBin2Line as Trash2, RiCloseLine as X } from "react-icons/ri";
import LoadingSpinner from "../../../components/shared/LoadingSpinner";

const DeleteVideoModal = ({ isOpen, onClose, video }) => {
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const handleDelete = async () => {
    setLoading(true);
    try {
      await dispatch(deleteVideo(video._id)).unwrap();
      showToast("Video deleted successfully", "success");
      onClose();
    } catch (error) {
      showToast(error || "Failed to delete video", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-md p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-red-600">Delete Video</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
            disabled={loading}
          >
            <X size={20} />
          </button>
        </div>

        <div className="space-y-4">
          <div className="flex items-center gap-3 text-red-600">
            <Trash2 size={24} />
            <p className="font-medium">This action cannot be undone</p>
          </div>

          <p className="text-gray-600">
            Are you sure you want to delete "{video?.title}"? This will
            permanently remove the video from your channel.
          </p>

          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:opacity-50 flex items-center gap-2"
              disabled={loading}
            >
              {loading ? (
                <>
                  <LoadingSpinner size="small" />
                  <span>Deleting...</span>
                </>
              ) : (
                "Delete"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteVideoModal;
