// Header.jsx
import React from "react";
import { UserButton } from "@clerk/clerk-react";
import { Upload } from "lucide-react";
import YivsterLogo from "../../assests/YivsterLogo.png";
import { Link } from "react-router-dom";

const Header = ({ isNavOpen, onUploadClick }) => {
  return (
    <header
      className={`
      h-14 
      bg-white 
      fixed 
      right-0 
      z-40 
      flex 
      items-center 
      justify-between 
      px-4
      transition-all 
      duration-300
      ${isNavOpen ? "left-64" : "left-20"}
    `}
    >
      <div className="flex items-center">
        <div className="h-14 flex items-center">
          <Link to="/" className="flex items-center">
            <img src={YivsterLogo} alt="Yivster Logo" className="h-60 w-auto" />
            <span className="ml-2 text-lg font-medium">Studio</span>
          </Link>
        </div>
      </div>

      <div className="flex items-center space-x-2">
        <button
          onClick={onUploadClick}
          className="flex items-center gap-2 px-6 py-2 text-white rounded-lg transition-all duration-200 hover:opacity-90"
          style={{
            background: "linear-gradient(to right, #B94EEF, #a235d4)",
            fontSize: "0.875rem",
            fontWeight: "500",
          }}
        >
          <Upload size={16} className="stroke-2" />
          <span>Create</span>
        </button>
        <div className="ml-2">
          <UserButton afterSignOutUrl="/" />
        </div>
      </div>
    </header>
  );
};

export default Header;
