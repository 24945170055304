import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  RiArrowLeftLine,
  RiEyeLine,
  RiTimeLine,
  RiUserLine,
  RiThumbUpLine,
  RiMessage2Line,
  RiShareLine,
} from "react-icons/ri";
import LoadingSpinner from "../../../components/shared/LoadingSpinner";
import api from "../../../api/axios";

const StatCard = ({ icon: Icon, title, value, change }) => (
  <div className="bg-gray-50 rounded-lg p-6">
    <div className="flex items-center justify-between mb-2">
      <div className="flex items-center gap-2">
        <Icon className="text-gray-400" size={20} />
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
      </div>
    </div>
    <p className="text-3xl font-bold">{value}</p>
    {change && (
      <p
        className={`text-sm mt-2 ${
          change >= 0 ? "text-green-500" : "text-red-500"
        }`}
      >
        {change > 0 ? "+" : ""}
        {change}% vs last period
      </p>
    )}
  </div>
);

const VideoAnalytics = () => {
  const { videoId } = useParams();
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState("last-28-days");

  useEffect(() => {
    const fetchVideoAnalytics = async () => {
      try {
        console.log("📊 Fetching analytics for video:", videoId);
        const response = await api.get(`/api/studio/videos/${videoId}`);
        console.log("📊 Video data received:", response.data);
        setVideoData(response.data);
      } catch (error) {
        console.error("❌ Error details:", error);
        setError(error.response?.data?.message || error.message);
      } finally {
        setLoading(false);
      }
    };

    if (videoId) {
      fetchVideoAnalytics();
    }
  }, [videoId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-red-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Error loading video analytics
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{error}</p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Link
              to="/content"
              className="text-sm font-medium text-red-600 hover:text-red-500"
            >
              ← Return to content
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white border-b border-gray-200 sticky top-0 z-10">
        <div className="px-6 py-4">
          <div className="flex items-center gap-4">
            <Link
              to="/content"
              className="p-2 hover:bg-gray-100 rounded-full text-gray-500"
            >
              <RiArrowLeftLine size={20} />
            </Link>
            <div className="flex-1">
              <h1 className="text-xl font-medium">Video analytics</h1>
              <p className="text-sm text-gray-500 mt-1">{videoData?.title}</p>
            </div>
            {/*     <select
              value={timeRange}
              onChange={(e) => setTimeRange(e.target.value)}
              className="bg-white border border-gray-200 rounded-lg px-4 py-2 text-sm"
            >
              <option value="last-28-days">Last 28 days</option>
              <option value="last-90-days">Last 90 days</option>
              <option value="lifetime">Lifetime</option>
            </select> */}
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="p-6">
        {/* Overview Card */}
        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
          <h2 className="text-lg font-medium mb-2">Overview</h2>
          <p className="text-gray-600 mb-6">
            Performance since{" "}
            {videoData?.createdAt
              ? new Date(videoData.createdAt).toLocaleDateString()
              : "publication"}
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <StatCard
              icon={RiEyeLine}
              title="Views"
              value={videoData?.views || 0}
              // change={2.5}
            />
            {/*      <StatCard
              icon={RiTimeLine}
              title="Watch time (hours)"
              value={(videoData?.watchTimeHours || 0).toFixed(1)}
              change={-1.2}
            /> */}
            {/*       <StatCard
              icon={RiUserLine}
              title="Subscribers"
              value={videoData?.subscribersGained || 0}
              // change={1.8}
            /> */}
            <StatCard
              icon={RiThumbUpLine}
              title="Likes"
              value={videoData?.likes || 0}
            />
            <StatCard
              icon={RiMessage2Line}
              title="Comments"
              value={videoData?.commentCount || 0}
            />
            {/* <StatCard
              icon={RiShareLine}
              title="Shares"
              value={videoData?.shares || 0}
            /> */}
          </div>
        </div>

        {/* Additional sections can be added here */}
      </div>
    </div>
  );
};

export default VideoAnalytics;
