// redux/slices/authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// redux/slices/authSlice.js

export const refreshAuthToken = createAsyncThunk(
  "auth/refreshToken",
  async (_, { rejectWithValue }) => {
    try {
      const auth = window.Clerk?.session;
      console.log("🔍 Clerk session:", auth ? "Found" : "Not found");

      if (auth) {
        const token = await auth.getToken();
        console.log(
          "✅ Token refreshed:",
          token ? "Token obtained" : "No token"
        );
        return { token, userId: auth.user.id };
      }
      console.log("❌ No auth session found");
      return rejectWithValue("No auth session found");
    } catch (error) {
      console.error("❌ Token refresh error:", error);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  token: null,
  loading: true,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshAuthToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshAuthToken.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.loading = false;
        state.error = null;
      })
      .addCase(refreshAuthToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setToken, clearToken } = authSlice.actions;
export default authSlice.reducer;
