import React from "react";
import {
  RiBarChartLine,
  RiUserLine,
  RiVideoLine,
  RiMessage2Line,
} from "react-icons/ri";

const DashboardLoading = () => {
  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Page Header Loading */}
      <div className="mb-8 animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-48 mb-2"></div>
        <div className="h-4 bg-gray-200 rounded w-72"></div>
      </div>

      {/* Stats Grid Loading */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {[
          { icon: RiUserLine, title: "Total Subscribers" },
          { icon: RiBarChartLine, title: "Total Views" },
          { icon: RiVideoLine, title: "Total Videos" },
          { icon: RiMessage2Line, title: "Total Comments" },
        ].map((stat, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-sm border border-gray-200 p-6"
          >
            <div className="flex items-center justify-between mb-4">
              <div className="h-4 bg-gray-200 rounded w-24 animate-pulse"></div>
              <span className="text-gray-300">
                <stat.icon size={20} />
              </span>
            </div>
            <div className="h-8 bg-gray-200 rounded w-20 animate-pulse"></div>
          </div>
        ))}
      </div>

      {/* Performance Section Loading */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-8">
        <div className="h-6 bg-gray-200 rounded w-40 mb-4 animate-pulse"></div>
        <div className="space-y-2 animate-pulse">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
        </div>
      </div>

      {/* Quick Actions Loading */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="h-6 bg-gray-200 rounded w-32 mb-4 animate-pulse"></div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="h-10 bg-gray-200 rounded animate-pulse"></div>
          {/* Uncomment these if you want to show more action buttons loading
          <div className="h-10 bg-gray-200 rounded animate-pulse"></div>
          <div className="h-10 bg-gray-200 rounded animate-pulse"></div>
          */}
        </div>
      </div>
    </div>
  );
};

export default DashboardLoading;
