// monetizationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/axios";
import { showToast } from "../../utils/toast";

// Async thunks
export const fetchAccountStatus = createAsyncThunk(
  "monetization/fetchAccountStatus",
  async () => {
    const response = await api.get("/stripe/account-status");
    return response.data;
  }
);

export const fetchEarnings = createAsyncThunk(
  "monetization/fetchEarnings",
  async () => {
    const response = await api.get("/stripe/earnings");
    return response.data;
  }
);

export const toggleTips = createAsyncThunk(
  "monetization/toggleTips",
  async (enabled, { rejectWithValue }) => {
    try {
      const response = await api.post("/stripe/toggle-tips", { enabled });
      if (response.data.url) {
        // If we get a URL, it means we need to redirect to Stripe onboarding
        window.location.href = response.data.url;
        return null; // Don't update state yet
      }
      showToast.success(
        enabled ? "Tips enabled successfully" : "Tips disabled successfully"
      );
      return response.data;
    } catch (error) {
      showToast.error(error.response?.data?.message || "Failed to toggle tips");
      return rejectWithValue(error.response?.data);
    }
  }
);

const initialState = {
  accountStatus: {
    hasAccount: false,
    chargesEnabled: false,
    payoutsEnabled: false,
    detailsSubmitted: false,
    tipsEnabled: false,
  },
  earnings: {
    estimatedRevenue: 0,
    tipsReceived: {
      amount: 0,
      count: 0,
    },
    totalEarnings: 0,
    periodLabel: "This Month",
  },
  loading: false,
  error: null,
};

const monetizationSlice = createSlice({
  name: "monetization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccountStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.accountStatus = action.payload;
      })
      .addCase(fetchAccountStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(toggleTips.pending, (state) => {
        state.loading = true;
      })
      .addCase(toggleTips.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          // Only update if we didn't redirect
          state.accountStatus.tipsEnabled = action.payload.tipsEnabled;
        }
      })
      .addCase(toggleTips.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to toggle tips";
      })
      .addCase(fetchEarnings.fulfilled, (state, action) => {
        state.earnings = action.payload;
      });
  },
});

export default monetizationSlice.reducer;
