const MonetizationSkeleton = () => (
  <div className="p-6 animate-pulse">
    <div className="h-8 bg-gray-200 rounded w-40 mb-6"></div>

    {/* Tips Section */}
    <div className="bg-white rounded-lg shadow p-6 mb-8">
      <div className="flex justify-between items-center mb-6">
        <div className="h-6 bg-gray-200 rounded w-32"></div>
        <div className="h-6 bg-gray-200 rounded w-24"></div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="bg-white p-6 rounded-lg shadow">
            <div className="h-4 bg-gray-200 rounded w-32 mb-4"></div>
            <div className="h-8 bg-gray-200 rounded w-24"></div>
          </div>
        ))}
      </div>
    </div>

    {/* Transaction History */}
    <div className="bg-white rounded-lg shadow">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <div className="h-6 bg-gray-200 rounded w-40"></div>
          <div className="h-8 bg-gray-200 rounded w-32"></div>
        </div>
      </div>
      <div className="p-8">
        {[...Array(3)].map((_, i) => (
          <div
            key={i}
            className="h-12 bg-gray-200 rounded mb-4 last:mb-0"
          ></div>
        ))}
      </div>
    </div>
  </div>
);

export default MonetizationSkeleton;
