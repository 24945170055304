import React, { useState, useRef, useEffect } from "react";
import { Upload, Copy, Plus, X, Image as ImageIcon } from "lucide-react";
import { useToast } from "../../components/providers/ToastProvider";
import Toast from "../../components/common/Toast";
import CustomizationSkeleton from "./CustomizationSkeleton";
import CustomizationError from "../../components/shared/ErrorState";
import api from "../../api/axios";

const ChannelCustomization = () => {
  const avatarInputRef = useRef(null);
  const bannerInputRef = useRef(null);
  const thumbnailInputRef = useRef(null);
  const { toast, showToast, clearToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [channelData, setChannelData] = useState({
    name: "",
    handle: "",
    description: "",
    avatar: null,
    banner: null,
    contactEmail: "",
    links: [],
    channelUrl: "",
  });

  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);

  // Fetch initial channel data

  const fetchChannelData = async () => {
    try {
      setLoading(true);
      setError(null); // Clear any existing errors

      const response = await api.get("/studio/customization");
      const existingLinks = response.data.socialLinks
        ? Object.entries(response.data.socialLinks)
            .filter(([_, url]) => url)
            .map(([title, url]) => ({ title, url }))
        : [];

      setChannelData({
        ...response.data,
        links: existingLinks,
      });
    } catch (error) {
      console.error("Error fetching channel data:", error);
      setError(error.response?.data?.message || "Failed to load channel data");
      showToast("Failed to load channel data", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChannelData();
  }, []);

  if (loading) {
    return <CustomizationSkeleton />;
  }

  if (error) {
    return <CustomizationError message={error} onRetry={fetchChannelData} />;
  }

  //console.log(channelData);

  const validateChannelBanner = (file) => {
    return new Promise((resolve, reject) => {
      // Validate file type and size first
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        reject(new Error("Banner must be JPG or PNG"));
        return;
      }

      if (file.size > 6 * 1024 * 1024) {
        // 6 MB for banners
        reject(new Error("Banner must be less than 6 MB"));
        return;
      }

      // Create image to check dimensions
      const img = new Image();
      img.onload = () => {
        // YouTube recommends banner size of 2560 x 1440 pixels
        const minWidth = 1280; // Minimum width
        const idealWidth = 2560; // Ideal width
        const idealHeight = 1440; // Ideal height
        const idealAspectRatio = 16 / 9;
        const tolerance = 0.2; // More tolerance for banners

        // Check minimum width
        if (img.width < minWidth) {
          reject(new Error(`Banner width must be at least ${minWidth} pixels`));
          return;
        }

        // Aspect ratio check
        const aspectRatio = img.width / img.height;
        if (Math.abs(aspectRatio - idealAspectRatio) > tolerance) {
          reject(
            new Error(
              `Banner should have a 16:9 aspect ratio. Current ratio is ${aspectRatio.toFixed(
                2
              )}`
            )
          );
          return;
        }

        // Optional: Provide guidance if not ideal dimensions
        if (img.width !== idealWidth || img.height !== idealHeight) {
          console.warn(
            `Recommended banner size is ${idealWidth}x${idealHeight} pixels`
          );
        }

        resolve(true);
      };

      img.onerror = () => {
        reject(new Error("Unable to load banner image"));
      };

      img.src = URL.createObjectURL(file);
    });
  };

  const validateChannelAvatar = (file) => {
    return new Promise((resolve, reject) => {
      // Validate file type
      if (!["image/jpeg", "image/png", "image/gif"].includes(file.type)) {
        reject(new Error("Channel avatar must be JPG, PNG, or GIF"));
        return;
      }

      // Validate file size (under 4MB)
      if (file.size > 4 * 1024 * 1024) {
        reject(new Error("Channel avatar must be less than 4 MB"));
        return;
      }

      // Create image to check dimensions
      const img = new Image();
      img.onload = () => {
        // YouTube recommends a square (1:1) aspect ratio for channel avatars
        const idealSize = 800; // Ideal dimension (width and height)

        // Check ideal size
        if (img.width !== idealSize || img.height !== idealSize) {
          reject(
            new Error(
              `Channel avatar should be ${idealSize}x${idealSize} pixels`
            )
          );
          return;
        }

        resolve(true);
      };

      img.onerror = () => {
        reject(new Error("Unable to load avatar image"));
      };

      img.src = URL.createObjectURL(file);
    });
  };

  const validateThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      // Validate file type and size first
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        reject(new Error("Thumbnail must be JPG or PNG"));
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        reject(new Error("Thumbnail must be less than 2 MB"));
        return;
      }

      // Create image to check dimensions
      const img = new Image();
      img.onload = () => {
        console.log("Image loaded. Dimensions:", img.width, "x", img.height);

        // Check width (min 640px, ideal 1280px)
        if (img.width < 640) {
          reject(new Error("Thumbnail width must be at least 640 pixels"));
          return;
        }

        // Strict 16:9 aspect ratio check
        const aspectRatio = img.width / img.height;
        const idealAspectRatio = 16 / 9;
        const tolerance = 0.1; // Allow slight variations

        console.log("Aspect ratio:", aspectRatio, "Ideal:", idealAspectRatio);

        if (Math.abs(aspectRatio - idealAspectRatio) > tolerance) {
          reject(
            new Error(
              `Thumbnail must have a 16:9 aspect ratio. Current ratio is ${aspectRatio.toFixed(
                2
              )}`
            )
          );
          return;
        }

        resolve(true);
      };

      img.onerror = () => {
        reject(new Error("Unable to load image"));
      };

      img.src = URL.createObjectURL(file);
    });
  };

  const handleFileSelect = async (type, event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (type === "banner") {
      try {
        await validateChannelBanner(file);
      } catch (error) {
        console.error("Banner validation error:", error);
        showToast(error.message, "error");
        return;
      }
    }

    if (type === "avatar") {
      try {
        await validateChannelAvatar(file);
      } catch (error) {
        console.error("Avatar validation error:", error);
        showToast(error.message, "error");
        return;
      }
    }

    const formData = new FormData();
    formData.append("channelImage", file);

    try {
      const response = await api.put(
        `/studio/customization/${type}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        setChannelData((prev) => ({
          ...prev,
          [type]: response.data[type],
        }));
        showToast(
          `${type.charAt(0).toUpperCase() + type.slice(1)} updated successfully`
        );
      }
    } catch (error) {
      showToast(`Failed to upload ${type}`, "error");
    }
  };

  const handleThumbnailSelect = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      await validateThumbnail(file);
      setThumbnailFile(file);

      // Create preview
      const reader = new FileReader();
      reader.onload = (e) => {
        const previewUrl = e.target.result;
        setThumbnailPreview(previewUrl);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Thumbnail validation error:", error);
      showToast(error.message, "error");
    }
  };

  const handleSaveInfo = async () => {
    try {
      await api.put("/studio/customization/info", {
        name: channelData.name,
        description: channelData.description,
      });
      showToast("Channel info updated successfully");
    } catch (error) {
      showToast("Failed to update channel info", "error");
    }
  };

  const handleSaveSocialLinks = async () => {
    try {
      const socialLinks = {};
      channelData.links.forEach((link) => {
        if (link.title && link.url) {
          socialLinks[link.title] = link.url;
        }
      });

      await api.put("/studio/customization/social-links", { socialLinks });
      showToast("Social links updated successfully");
    } catch (error) {
      showToast("Failed to update social links", "error");
    }
  };

  const handleSaveContactEmail = async () => {
    try {
      await api.put("/studio/customization/contact", {
        email: channelData.contactEmail,
      });
      showToast("Contact email updated successfully");
    } catch (error) {
      showToast("Failed to update contact email", "error");
    }
  };

  const handleSave = async () => {
    try {
      await Promise.all([
        handleSaveInfo(),
        handleSaveSocialLinks(),
        handleSaveContactEmail(),
      ]);
      showToast("All changes saved successfully");
    } catch (error) {
      showToast("Failed to save some changes", "error");
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(channelData.channelUrl);
    showToast("Channel URL copied to clipboard");
  };

  const addNewLink = () => {
    setChannelData((prev) => ({
      ...prev,
      links: [...prev.links, { title: "", url: "" }],
    }));
  };

  const updateLink = (index, field, value) => {
    const updatedLinks = [...channelData.links];
    updatedLinks[index][field] = value;

    // Only validate URL when it's being changed and not empty
    if (field === "url" && value) {
      try {
        // Allow URLs without protocol by adding https:// if missing
        if (!value.match(/^https?:\/\//)) {
          value = "https://" + value;
        }
        new URL(value); // Basic URL validation
      } catch {
        showToast("Please enter a valid URL", "error");
        return;
      }
    }

    setChannelData((prev) => ({
      ...prev,
      links: updatedLinks,
    }));
  };

  const removeLink = (index) => {
    setChannelData((prev) => ({
      ...prev,
      links: prev.links.filter((_, i) => i !== index),
    }));
  };

  console.log("Channel data:", channelData.ownerId?.imageUrl);

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800 p-6">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Channel customization</h1>
          <div className="space-x-4">
            <button className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200">
              View channel
            </button>
            <button className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200">
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="px-4 py-2 text-white rounded"
              style={{ backgroundColor: "#B94EEF" }}
            >
              Publish
            </button>
          </div>
        </div>

        <div className="space-y-8">
          {/* Banner Image Section */}
          {/* Banner Image Section */}
          <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm flex">
            {/* Left side - Banner image */}
            <div className="w-64">
              <div className="border-2 border-dashed border-gray-300 rounded">
                {channelData.banner ? (
                  <img
                    src={channelData.banner}
                    alt="Banner"
                    className="w-full max-h-36 object-cover rounded"
                  />
                ) : (
                  <div className="text-gray-500 p-8 text-center">
                    <Upload className="mx-auto mb-2 text-gray-400" />
                    <p>Upload banner image</p>
                  </div>
                )}
              </div>
              <input
                type="file"
                ref={bannerInputRef}
                onChange={(e) => handleFileSelect("banner", e)}
                className="hidden"
                accept="image/*"
              />
            </div>

            {/* Right side - Text and buttons */}
            <div className="flex-1 ml-8">
              <p className="text-gray-500 mb-4">
                For the best results on all devices, use an image that's at
                least 2048 x 1152 pixels and less than 6MB.
              </p>
              <div className="flex gap-2">
                <button
                  onClick={() => bannerInputRef.current?.click()}
                  className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                >
                  Change
                </button>
                {channelData.banner && (
                  <button
                    onClick={() =>
                      setChannelData((prev) => ({ ...prev, banner: null }))
                    }
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Profile Picture Section */}
          <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm flex items-start mt-8">
            <div>
              <div className="w-32 h-32 bg-gray-100 rounded-full flex items-center justify-center text-gray-500">
                {channelData.avatar ? (
                  <img
                    src={channelData.avatar}
                    alt="Profile"
                    className="w-32 h-32 rounded-full"
                  />
                ) : (
                  <Upload className="text-gray-400" />
                )}
              </div>
              <div className="mt-4 space-x-4">
                {/*  <button
                  onClick={() => avatarInputRef.current?.click()}
                  className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                >
                  Change
                </button> */}
                {/*      {channelData.avatar && (
                  <button
                    onClick={() =>
                      setChannelData((prev) => ({ ...prev, avatar: null }))
                    }
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                  >
                    Remove
                  </button>
                )} */}
              </div>
              {/*    <input
                type="file"
                ref={avatarInputRef}
                onChange={(e) => handleFileSelect("avatar", e)}
                className="hidden"
                accept="image/*"
              /> */}
            </div>
            <div className="flex-1 ml-8">
              <p className="text-gray-500 mb-2">
                It's recommended to use a picture that's at least 1:1 and less
                than 4MB. Use a PNG or GIF (no animations) file. Make sure your
                picture follows the Yivster Community Guidelines.
              </p>
            </div>
          </div>
          {/* Basic Info Section */}
          <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm space-y-6">
            <div>
              <h2 className="text-lg font-semibold mb-2">Name</h2>
              <input
                type="text"
                value={channelData.name}
                onChange={(e) =>
                  setChannelData((prev) => ({ ...prev, name: e.target.value }))
                }
                className="w-full p-2 bg-gray-50 rounded border border-gray-300 focus:border-green-500 focus:ring-1 focus:ring-green-500"
              />
            </div>

            <div>
              <h2 className="text-lg font-semibold mb-2">Handle</h2>
              <input
                type="text"
                value={channelData.handle}
                disabled
                className="w-full p-2 bg-gray-100 rounded opacity-50"
              />
            </div>

            <div>
              <h2 className="text-lg font-semibold mb-2">Description</h2>
              <textarea
                value={channelData.description}
                onChange={(e) =>
                  setChannelData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                className="w-full p-2 bg-gray-50 rounded border border-gray-300 focus:border-green-500 focus:ring-1 focus:ring-green-500 h-32"
              />
            </div>
          </div>
          {/* Links Section */}
          <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold mb-4">Links</h2>
            <p className="text-gray-500 mb-4">
              Share external links with your viewers. They'll be visible on your
              channel profile and about page.
            </p>
            <div className="space-y-4">
              {channelData.links.map((link, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <div className="flex-shrink-0 w-8 flex items-center justify-center">
                    <button className="text-gray-400 hover:text-gray-600">
                      ≡
                    </button>
                  </div>
                  <div className="flex-1 grid grid-cols-2 gap-4">
                    <input
                      type="text"
                      value={link.title}
                      onChange={(e) =>
                        updateLink(index, "title", e.target.value)
                      }
                      className="px-3 py-2 bg-gray-50 rounded border border-gray-300 focus:border-green-500 focus:ring-1 focus:ring-green-500"
                      placeholder="Link title (required)"
                    />
                    <div className="flex space-x-2">
                      <input
                        type="text"
                        value={link.url}
                        onChange={(e) =>
                          updateLink(index, "url", e.target.value)
                        }
                        className="flex-1 px-3 py-2 bg-gray-50 rounded border border-gray-300 focus:border-green-500 focus:ring-1 focus:ring-green-500"
                        placeholder="URL (required)"
                      />
                      <button
                        onClick={() => removeLink(index)}
                        className="p-2 hover:bg-gray-100 rounded"
                      >
                        <X className="h-5 w-5 text-gray-400 hover:text-gray-600" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <button
                onClick={addNewLink}
                className="flex items-center space-x-2 text-green-600 hover:text-green-700"
              >
                <Plus className="h-4 w-4" />
                <span>Add link</span>
              </button>
            </div>
          </div>
          {/* Channel URL Section */}
          <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold mb-2">Channel URL</h2>
            <div className="flex space-x-2">
              <input
                type="text"
                value={channelData.channelUrl}
                readOnly
                className="flex-1 p-2 bg-gray-50 rounded border border-gray-300"
              />
              <button
                onClick={handleCopyUrl}
                className="p-2 bg-gray-100 rounded hover:bg-gray-200"
              >
                <Copy size={20} className="text-gray-600" />
              </button>
            </div>
          </div>
          {/* Contact Info Section */}
          <div className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold mb-2">Contact info</h2>
            <input
              type="email"
              value={channelData.contactEmail}
              onChange={(e) =>
                setChannelData((prev) => ({
                  ...prev,
                  contactEmail: e.target.value,
                }))
              }
              placeholder="Email address"
              className="w-full p-2 bg-gray-50 rounded border border-gray-300 focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
          </div>
        </div>
      </div>

      {toast && (
        <Toast message={toast.message} type={toast.type} onClose={clearToast} />
      )}
    </div>
  );
};

export default ChannelCustomization;
