import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllComments, setFilter } from "../redux/slices/commentsSlice";
import { MessageCircle } from "lucide-react";

const Community = () => {
  const dispatch = useDispatch();
  const { comments, loading, filter } = useSelector((state) => state.comments);

  useEffect(() => {
    dispatch(fetchAllComments());
  }, [dispatch]);

  return (
    <div className="bg-gray-50 min-h-screen p-6">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">Community</h1>

        {/* Filter Tabs */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm mb-6">
          <div className="px-6 py-4 border-b">
            <div className="flex justify-between items-center">
              <div className="flex gap-8">
                <button
                  className={`pb-2 ${
                    filter === "all"
                      ? "border-b-2 border-blue-600 text-blue-600"
                      : "text-gray-500 hover:text-gray-700"
                  }`}
                  onClick={() => dispatch(setFilter("all"))}
                >
                  Comments
                </button>
                {/*  <button
                  className="pb-4 px-2 text-gray-400 cursor-not-allowed"
                  disabled
                >
                  Mentions
                </button> */}
              </div>

              {/* Filter Chips */}
              <div className="flex gap-2">
                <button
                  className={`px-4 py-2 rounded-full text-sm transition-colors ${
                    filter === "published"
                      ? "bg-blue-100 text-blue-700"
                      : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                  }`}
                  onClick={() => dispatch(setFilter("published"))}
                >
                  Published
                </button>
                {/*    <button
                  className={`px-4 py-2 rounded-full ${
                    filter === "held"
                      ? "bg-gray-200"
                      : "bg-gray-100 hover:bg-gray-200"
                  }`}
                  onClick={() => dispatch(setFilter("held"))}
                >
                  Held
                </button> */}
              </div>
            </div>
          </div>

          {/* Comments List */}
          {loading ? (
            <div className="p-12 text-center">
              <div className="animate-pulse">
                <MessageCircle className="mx-auto text-4xl text-gray-300 mb-4 w-12 h-12" />
                <p className="text-gray-500">Loading comments...</p>
              </div>
            </div>
          ) : comments.length === 0 ? (
            <div className="p-12 text-center">
              <MessageCircle className="mx-auto text-6xl text-gray-300 mb-6 w-16 h-16" />
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                No comments yet
              </h3>
              <p className="text-gray-500">
                Comments on your videos will appear here
              </p>
            </div>
          ) : (
            <div>
              {comments.map((comment) => (
                <div
                  key={comment._id}
                  className="px-6 py-4 border-b last:border-b-0 hover:bg-gray-50 transition-colors"
                >
                  <div className="flex items-start space-x-4">
                    <img
                      src={comment.userId?.imageUrl}
                      alt=""
                      className="w-10 h-10 rounded-full object-cover"
                    />
                    <div className="flex-1">
                      <div className="flex justify-between items-baseline mb-2">
                        <div>
                          <span className="font-semibold text-gray-800 mr-2">
                            {comment.userId?.username}
                          </span>
                          <span className="text-sm text-gray-500">
                            {new Date(comment.createdAt).toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                      <p className="text-gray-700 mb-3">{comment.content}</p>

                      {/* Video Information */}
                      <div className="bg-gray-100 rounded-lg p-3 flex items-center space-x-4">
                        <img
                          src={comment.videoThumbnail}
                          alt={comment.videoTitle}
                          className="w-24 h-16 rounded object-cover"
                        />
                        <div>
                          <h4 className="font-medium text-sm text-gray-800">
                            {comment.videoTitle}
                          </h4>
                          <p className="text-xs text-gray-500">
                            {new Date(
                              comment.videoCreatedAt
                            ).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Community;
