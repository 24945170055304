import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/axios";

// Async thunks
export const fetchChannelData = createAsyncThunk(
  "channel/fetchChannelData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/channels/me");
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch channel data"
      );
    }
  }
);

export const updateChannelData = createAsyncThunk(
  "channel/updateChannelData",
  async (channelData, { rejectWithValue }) => {
    try {
      const response = await api.put("/channels/me", channelData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to update channel data"
      );
    }
  }
);

export const uploadChannelImage = createAsyncThunk(
  "channel/uploadImage",
  async ({ type, file }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("channelImage", file);

      const response = await api.put(
        `/studio/customization/${type}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || `Failed to upload ${type}`
      );
    }
  }
);

// Add new thunk for fetching creator status
export const fetchCreatorStatus = createAsyncThunk(
  "channel/fetchCreatorStatus",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/creator-status/status");
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch creator status"
      );
    }
  }
);

// Add new thunk for submitting creator application
/* export const submitCreatorApplication = createAsyncThunk(
  "channel/submitApplication",
  async (applicationData, { getState, rejectWithValue }) => {
    try {
      const currentChannel = getState().channel.data;
      console.log("Current Channel:", currentChannel); // Debug log

      if (!currentChannel) {
        throw new Error("No channel found");
      }

      const dataToSubmit = {
        ...applicationData,
        channelId: currentChannel._id,
      };

      console.log("Submitting application data:", dataToSubmit); // Debug log

      const response = await api.post(
        "/api/creator-applications/submit",
        dataToSubmit
      );

      console.log("Application submission response:", response.data); // Debug log
      return response.data;
    } catch (error) {
      console.error("Application submission error:", error.response || error); // Detailed error log
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to submit application"
      );
    }
  }
); */

export const submitCreatorApplication = createAsyncThunk(
  "channel/submitApplication",
  async (applicationData, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/creator-applications/submit",
        applicationData
      );
      return response.data;
    } catch (error) {
      // Return the exact error message from the backend
      return rejectWithValue(
        error.response?.data?.message || "Failed to submit application"
      );
    }
  }
);

const channelSlice = createSlice({
  name: "channel",
  initialState: {
    data: null,
    loading: false,
    error: null,
    creatorStatus: {
      status: "none", // Change this to "none"
      canUpload: false,
      applicationDetails: null,
      loading: false,
      error: null,
    },
  },

  reducers: {
    clearChannelError: (state) => {
      state.error = null;
    },
    clearCreatorStatusError: (state) => {
      state.creatorStatus.error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      // Fetch channel data
      .addCase(fetchChannelData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChannelData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchChannelData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update channel data
      .addCase(updateChannelData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateChannelData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(updateChannelData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(uploadChannelImage.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.avatar) {
          state.data.avatar = action.payload.avatar;
        }
        if (action.payload.banner) {
          state.data.banner = action.payload.banner;
        }
      })
      // Add cases for creator status
      .addCase(fetchCreatorStatus.pending, (state) => {
        state.creatorStatus.loading = true;
        state.creatorStatus.error = null;
      })
      .addCase(fetchCreatorStatus.fulfilled, (state, action) => {
        state.creatorStatus.loading = false;
        state.creatorStatus.status = action.payload.creatorStatus;
        state.creatorStatus.canUpload = action.payload.canUpload;
        state.creatorStatus.applicationDetails = action.payload.application;
      })
      .addCase(fetchCreatorStatus.rejected, (state, action) => {
        state.creatorStatus.loading = false;
        state.creatorStatus.error = action.payload;
      })

      // Add cases for application submission
      .addCase(submitCreatorApplication.pending, (state) => {
        state.creatorStatus.loading = true;
        state.creatorStatus.error = null;
      })
      .addCase(submitCreatorApplication.fulfilled, (state, action) => {
        state.creatorStatus.loading = false;
        state.creatorStatus.status = "pending"; // Make sure this is being set
        state.creatorStatus.applicationDetails = action.payload.application;
      })
      .addCase(submitCreatorApplication.rejected, (state, action) => {
        state.creatorStatus.loading = false;
        state.creatorStatus.error = action.payload;
      });
  },
});

export const { clearChannelError, clearCreatorStatusError } =
  channelSlice.actions;
export default channelSlice.reducer;
