// components/creator/CreatorApplicationModal.jsx
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { X } from "lucide-react";
import { useToast } from "../providers/ToastProvider";
import { submitCreatorApplication } from "../../redux/slices/channelSlice";
import { fetchCreatorStatus } from "../../redux/slices/channelSlice";
import YivsterLogo from "../../assests/YivsterLogo.png";

const CreatorApplicationModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const { status: creatorStatus } = useSelector(
    (state) => state.channel.creatorStatus
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Add this - was missing before
  const requirements = [
    "Yivster requires a minimum of 10,000 followers across all socials",
    "Content must be original and not infringe on any copyright restrictions",
    "Must follow community guidelines",
    "Regular content uploads required",
  ];

  const [formData, setFormData] = useState({
    fullName: "",
    channelName: "",
    email: "",
    socialLinks: [""],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const applicationData = {
        fullName: formData.fullName,
        channelName: formData.channelName,
        email: formData.email,
        socialLinks: formData.socialLinks.filter((link) => link !== ""),
      };

      await dispatch(submitCreatorApplication(applicationData)).unwrap();
      await dispatch(fetchCreatorStatus()); // Add this to refresh the status
      showToast("Application submitted successfully");
      onClose();
    } catch (error) {
      console.error("Submission error:", error);
      showToast(error?.message || "Failed to submit application");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-xl relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X size={24} />
        </button>

        {creatorStatus === "pending" ? (
          <div className="p-6">
            <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
              <h3 className="font-medium text-yellow-800">
                Application Under Review
              </h3>
              <p className="text-sm text-yellow-600 mt-2">
                Your application is currently being reviewed. We'll notify you
                once a decision has been made.
              </p>
            </div>
          </div>
        ) : (
          <div className="p-6">
            <div className="text-center">
              <img
                src={YivsterLogo}
                alt="Yivster Logo"
                className="mx-auto mb-4 w-28 h-28 object-contain"
              />
              <h2 className="text-xl font-semibold mb-6">
                Creator Application
              </h2>
            </div>

            <div className="mb-6">
              <p className="font-medium mb-2">Requirements:</p>
              <ul className="text-sm space-y-1">
                {requirements.map((req, index) => (
                  <li key={index}>• {req}</li>
                ))}
              </ul>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="text"
                placeholder="Full Name"
                value={formData.fullName}
                onChange={(e) =>
                  setFormData({ ...formData, fullName: e.target.value })
                }
                className="w-full p-2 border rounded"
                required
              />

              <input
                type="text"
                placeholder="Channel Name"
                value={formData.channelName}
                onChange={(e) =>
                  setFormData({ ...formData, channelName: e.target.value })
                }
                className="w-full p-2 border rounded"
                required
              />

              <input
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                className="w-full p-2 border rounded"
                required
              />

              <input
                type="url"
                placeholder="Social Media Link"
                value={formData.socialLinks[0]}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    socialLinks: [e.target.value],
                  });
                }}
                className="w-full p-2 border rounded"
              />

              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full p-2 text-white rounded ${
                  isSubmitting
                    ? "bg-purple-400"
                    : "bg-purple-600 hover:bg-purple-700"
                }`}
              >
                {isSubmitting ? "Submitting..." : "Submit Application"}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreatorApplicationModal;
