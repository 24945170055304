import React from "react";
import { AlertCircle, RefreshCcw } from "lucide-react";

const ErrorState = ({
  message = "Something went wrong",
  onRetry = null,
  fullScreen = true,
}) => {
  const containerClasses = fullScreen
    ? "flex flex-col justify-center items-center h-screen bg-gray-50 px-4"
    : "flex flex-col justify-center items-center p-12 bg-gray-50";

  return (
    <div className={containerClasses}>
      <div className="text-center">
        <AlertCircle className="mx-auto h-12 w-12 text-red-500 mb-4" />
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          Unable to load data
        </h3>
        <p className="text-gray-500 mb-6 max-w-md">{message}</p>
        {onRetry && (
          <button
            onClick={onRetry}
            className="inline-flex items-center px-4 py-2 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
          >
            <RefreshCcw className="w-4 h-4 mr-2" />
            Try again
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorState;
