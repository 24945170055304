// Layout.jsx
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchCreatorStatus } from "../../redux/slices/channelSlice";
import Header from "./Header";
import Sidebar from "./Sidebar";
import UploadVideoModal from "../videos/UploadVideoModal";
import CreatorApplicationModal from "../creator/CreatorApplicationModal";

const Layout = () => {
  const dispatch = useDispatch();
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [applicationModalOpen, setApplicationModalOpen] = useState(false);

  // Get creator status from Redux
  const { status: creatorStatus, canUpload } = useSelector(
    (state) => state.channel.creatorStatus
  );

  // Fetch creator status when component mounts
  useEffect(() => {
    dispatch(fetchCreatorStatus());
  }, [dispatch]);

  const handleUploadClick = () => {
    if (creatorStatus === "approved" && canUpload) {
      setUploadModalOpen(true);
    } else {
      setApplicationModalOpen(true);
    }
  };

  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebar isOpen={isNavOpen} onToggle={() => setIsNavOpen(!isNavOpen)} />

      <div
        className={`flex-1 flex flex-col transition-all duration-300 ${
          isNavOpen ? "ml-64" : "ml-20"
        }`}
      >
        <Header isNavOpen={isNavOpen} onUploadClick={handleUploadClick} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-50 pt-14">
          <Outlet context={{ onUploadClick: handleUploadClick }} />
        </main>
      </div>

      {/* Upload Modal */}
      {uploadModalOpen && (
        <UploadVideoModal
          isOpen={uploadModalOpen}
          onClose={() => setUploadModalOpen(false)}
        />
      )}

      {/* Creator Application Modal */}
      {applicationModalOpen && (
        <CreatorApplicationModal
          isOpen={applicationModalOpen}
          onClose={() => setApplicationModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Layout;
