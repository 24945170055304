import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchVideoDetails } from "../redux/slices/videoSlice";
import LoadingSpinner from "../components/shared/LoadingSpinner";
import ErrorAlert from "../components/shared/ErrorAlert";

const VideoStats = () => {
  const { videoId } = useParams();
  const dispatch = useDispatch();
  const { currentVideo, loading, error } = useSelector((state) => state.videos);

  useEffect(() => {
    if (videoId) {
      dispatch(fetchVideoDetails(videoId));
    }
  }, [dispatch, videoId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorAlert message={error} />;
  if (!currentVideo) return <div>Video not found</div>;

  return (
    <div className="p-6">
      {/* Basic Video Info */}
      <div className="bg-white rounded-lg shadow p-6 mb-6">
        <h1 className="text-2xl font-bold mb-4">{currentVideo.title}</h1>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div>
            <p className="text-gray-500 text-sm">Views</p>
            <p className="text-xl font-semibold">{currentVideo.views}</p>
          </div>
          <div>
            <p className="text-gray-500 text-sm">Likes</p>
            <p className="text-xl font-semibold">{currentVideo.likes}</p>
          </div>
          <div>
            <p className="text-gray-500 text-sm">Comments</p>
            <p className="text-xl font-semibold">{currentVideo.comments}</p>
          </div>
          <div>
            <p className="text-gray-500 text-sm">Upload Date</p>
            <p className="text-xl font-semibold">
              {new Date(currentVideo.createdAt).toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>

      {/* Basic Performance Stats */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-lg font-semibold mb-4">Performance</h2>
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Average View Duration</span>
            <span className="font-semibold">
              {currentVideo.avgViewDuration || "0:00"}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-600">Watch Time (hours)</span>
            <span className="font-semibold">
              {currentVideo.watchTime || "0"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoStats;
