import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccountStatus,
  toggleTips,
  fetchEarnings,
} from "../../redux/slices/monetizationSlice";
import MonetizationSkeleton from "./MonetizationSkeleton";
import ErrorState from "../../components/shared/ErrorState";

const StatsCard = ({ title, amount = 0, count, period }) => (
  <div className="bg-white p-6 rounded-lg shadow">
    <h3 className="text-gray-500">{title}</h3>
    <div className="mt-2">
      <div className="flex justify-between items-baseline">
        <span className="text-2xl font-bold">
          ${typeof amount === "number" ? amount.toFixed(2) : "0.00"}
        </span>
        <span className="text-sm text-gray-500">{period}</span>
      </div>
      {count !== undefined && (
        <div className="mt-1 text-sm text-gray-600">{count} tips received</div>
      )}
    </div>
  </div>
);

const Monetization = () => {
  const dispatch = useDispatch();
  const { earnings, loading, accountStatus, error } = useSelector(
    (state) => state.monetization
  );
  const { estimatedRevenue, tipsReceived, totalEarnings, periodLabel } =
    earnings || {};
  const { count: tipCount = 0 } = tipsReceived || {};
  /*   const { accountStatus, earnings, loading } = useSelector(
    (state) => state.monetization
  ); */
  const [currentPeriod, setCurrentPeriod] = useState("This Month");

  // Fetch account status on mount
  useEffect(() => {
    dispatch(fetchAccountStatus());
  }, [dispatch]);

  // Fetch earnings when tips are enabled or period changes
  useEffect(() => {
    if (accountStatus.tipsEnabled) {
      dispatch(fetchEarnings(currentPeriod));
    }
  }, [dispatch, accountStatus.tipsEnabled, currentPeriod]);

  const handleConnectStripe = async () => {
    dispatch(toggleTips(true));
  };

  const handleToggleTips = async () => {
    dispatch(toggleTips(!accountStatus.tipsEnabled));
  };

  // Show different UI based on Stripe account status
  const renderTipsSection = () => {
    if (!accountStatus.hasAccount) {
      return (
        <div className="bg-white rounded-lg shadow p-6 mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Tips Settings</h2>
          </div>
          <p className="text-gray-600 mb-6">
            Enable tips to start receiving support from your viewers. You'll
            need to connect your Stripe account.
          </p>
          <button
            onClick={handleConnectStripe}
            className="text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
            style={{ backgroundColor: "#B94EEF" }}
            disabled={loading}
          >
            Connect to Stripe
          </button>
        </div>
      );
    }

    return (
      <div className="bg-white rounded-lg shadow p-6 mb-8">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Tips Settings</h2>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={accountStatus.tipsEnabled}
              onChange={handleToggleTips}
              disabled={loading}
            />
            <div
              className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"
              style={{ backgroundColor: "#B94EEF" }}
            ></div>{" "}
            <span className="ml-3 text-sm font-medium text-gray-700">
              Tips {accountStatus.tipsEnabled ? "Enabled" : "Disabled"}
            </span>
          </label>
        </div>

        {/* Only show earnings info if tips are enabled */}
        {accountStatus.tipsEnabled && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <StatsCard
              title="Estimated Revenue"
              amount={earnings?.estimatedRevenue || 0}
              period="This Month"
            />
            <StatsCard
              title="Tips Received"
              amount={tipsReceived?.amount || 0}
              count={tipCount}
              period={periodLabel || "This Month"}
            />
            <StatsCard
              title="Total Earnings"
              amount={earnings?.totalEarnings || 0}
              period="All Time"
            />
          </div>
        )}
      </div>
    );
  };

  if (loading) {
    return <MonetizationSkeleton />; // We created this earlier
  }

  if (error) {
    return (
      <ErrorState
        message={error}
        onRetry={() => {
          dispatch(fetchAccountStatus());
          if (accountStatus?.tipsEnabled) {
            dispatch(fetchEarnings(currentPeriod));
          }
        }}
      />
    );
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Monetization</h1>

      {renderTipsSection()}

      {/* Only show transaction history if Stripe is connected and tips are enabled */}
      {accountStatus.hasAccount && accountStatus.tipsEnabled && (
        <div className="bg-white rounded-lg shadow">
          <div className="px-6 py-4 border-b border-gray-200">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold">Transaction History</h2>
              <select
                className="border rounded-lg px-3 py-2"
                value={currentPeriod}
                onChange={(e) => setCurrentPeriod(e.target.value)}
              >
                <option>This Month</option>
                <option>Last Month</option>
                <option>Last 3 Months</option>
                <option>All Time</option>
              </select>
            </div>
          </div>
          <div className="p-6 text-center text-gray-500">
            <div className="mb-4">
              <svg
                className="w-12 h-12 mx-auto text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <p>No transactions yet</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Monetization;
