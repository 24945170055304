import axios from "axios";
import { store } from "../redux/store";
import { refreshAuthToken } from "../redux/slices/authSlice";

const api = axios.create({
  baseURL: "/api",
  withCredentials: true,
});

api.interceptors.request.use(async (config) => {
  try {
    const state = store.getState();
    let token = state.auth.token;
    console.log("🔑 Initial token state:", token ? "Present" : "Missing");

    if (!token) {
      console.log("🔄 Attempting to refresh token...");
      const result = await store.dispatch(refreshAuthToken());
      token = result.payload?.token;
      console.log("🎯 Refresh result:", result.payload ? "Success" : "Failed");
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log("✅ Request headers set with token");
    } else {
      console.log("❌ No token available after refresh attempt");
    }

    return config;
  } catch (error) {
    console.error("❌ Request interceptor error:", error);
    return Promise.reject(error);
  }
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.log("📥 Response error:", {
      status: error.response?.status,
      url: originalRequest?.url,
    });

    if (error.response?.status === 401 && !originalRequest._retry) {
      console.log("🔄 Attempting retry with new token...");
      originalRequest._retry = true;

      try {
        await store.dispatch(refreshAuthToken());
        return api(originalRequest);
      } catch (refreshError) {
        console.error("❌ Retry failed:", refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
