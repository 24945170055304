import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/axios";

// Update the fetchVideos thunk to return MOCK_VIDEOS directly:
/* export const fetchVideos = createAsyncThunk(
  "videos/fetchVideos",
  async (_, { rejectWithValue }) => {
    try {
      if (process.env.NODE_ENV === "development") {
        return MOCK_VIDEOS; // Return mock data directly
      }
      const response = await api.get("/api/studio/videos");
      return response.data;
    } catch (error) {
      console.error("Error fetching videos:", error);
      return rejectWithValue("Failed to fetch videos");
    }
  }
); */

export const fetchVideos = createAsyncThunk(
  "videos/fetchVideos",
  async (_, { rejectWithValue }) => {
    try {
      console.log("Fetching videos from API...");
      const response = await api.get("/studio/videos");
      console.log("API Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching videos:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch videos"
      );
    }
  }
);

export const uploadVideo = createAsyncThunk(
  "videos/upload",
  async (videoData, { rejectWithValue }) => {
    try {
      if (process.env.NODE_ENV === "development") {
        // Simulate successful upload
        return { ...videoData, id: Date.now() }; // Mock response with a new ID
      }
      const response = await api.post("/videos", videoData);
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to upload video");
    }
  }
);

// src/redux/slices/videoSlice.js
export const updateVideo = createAsyncThunk(
  "videos/updateVideo",
  async ({ id, updates }, { rejectWithValue }) => {
    // Changed from videoId to id
    try {
      console.log("🔄 Updating video:", { id, updates });
      const response = await api.put(`/studio/videos/${id}`, updates);
      console.log("✅ Update response:", response.data);
      return response.data;
    } catch (error) {
      console.error("❌ Update error:", {
        status: error.response?.status,
        message: error.response?.data?.message || error.message,
      });
      return rejectWithValue(
        error.response?.data?.message || "Failed to update video"
      );
    }
  }
);

export const deleteVideo = createAsyncThunk(
  "videos/deleteVideo",
  async (id, { rejectWithValue }) => {
    try {
      console.log("🗑️ Deleting video:", id);
      await api.delete(`/studio/videos/${id}`);
      return id;
    } catch (error) {
      console.error("❌ Delete error:", {
        status: error.response?.status,
        message: error.response?.data?.message || error.message,
      });
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete video"
      );
    }
  }
);

export const fetchVideoDetails = createAsyncThunk(
  "videos/fetchVideoDetails",
  async (videoId, { rejectWithValue }) => {
    try {
      /*     if (process.env.NODE_ENV === "development") {
        return MOCK_VIDEOS.items.find((v) => v.id === videoId) || null;
      } */

      const response = await api.get(`/videos/${videoId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch video details");
    }
  }
);

const videoSlice = createSlice({
  name: "videos",
  initialState: {
    items: [],
    currentVideo: null,
    loading: false,
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchVideos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(uploadVideo.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      .addCase(updateVideo.fulfilled, (state, action) => {
        const index = state.items.findIndex(
          (video) => video._id === action.payload._id
        );
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        state.items = state.items.filter(
          (video) => video._id !== action.payload
        );
      })
      .addCase(fetchVideoDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVideoDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currentVideo = action.payload;
      })
      .addCase(fetchVideoDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default videoSlice.reducer;
