// Sidebar.jsx
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  RiDashboardLine,
  RiVideoLine,
  RiBarChartLine,
  RiMoneyDollarCircleLine,
  RiGroupLine,
  RiPaletteLine,
  RiMenuFoldLine,
  RiMenuUnfoldLine,
} from "react-icons/ri";
import { BiMenu } from "react-icons/bi";

const Sidebar = ({ isOpen, onToggle }) => {
  const location = useLocation();

  const menuItems = [
    {
      title: "Dashboard",
      icon: <RiDashboardLine size={22} />,
      path: "/dashboard",
    },
    { title: "Content", icon: <RiVideoLine size={22} />, path: "/content" },
    {
      title: "Analytics",
      icon: <RiBarChartLine size={22} />,
      path: "/analytics",
    },
    {
      title: "Monetization",
      icon: <RiMoneyDollarCircleLine size={22} />,
      path: "/monetization",
    },
    { title: "Community", icon: <RiGroupLine size={22} />, path: "/community" },
    {
      title: "Customization",
      icon: <RiPaletteLine size={22} />,
      path: "/customization",
    },
  ];

  const isActive = (path) => location.pathname === path;

  return (
    <div
      className={`
      h-screen 
      bg-white 
      fixed 
      transition-all 
      duration-300 
      z-50
      ${isOpen ? "w-64" : "w-20"}
    `}
    >
      <div className="h-14 flex items-center px-3">
        <div className="w-14 flex justify-center">
          <button
            onClick={onToggle}
            className="p-2 hover:bg-gray-100 rounded-full text-gray-600"
          >
            {isOpen ? <BiMenu size={24} /> : <BiMenu size={24} />}
          </button>
        </div>
        {/*    {isOpen && (
          <span className="text-sm font-medium ml-2">Yivster Studio</span>
        )} */}
      </div>

      <nav className="mt-2">
        {menuItems.map((item) => (
          <Link
            key={item.title}
            to={item.path}
            className={`
              flex items-center h-12 px-3
              ${
                isActive(item.path)
                  ? "bg-gray-100 text-blue-600"
                  : "text-gray-600 hover:bg-gray-50"
              }
              transition-colors duration-200
            `}
            title={!isOpen ? item.title : ""}
          >
            <div className="w-14 flex justify-center">{item.icon}</div>
            {isOpen && <span className="text-sm">{item.title}</span>}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;
