// src/redux/slices/analyticsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/axios";

export const fetchChannelAnalytics = createAsyncThunk(
  "analytics/fetchChannelAnalytics",
  async (_, { rejectWithValue }) => {
    try {
      console.log("Fetching channel analytics...");
      const response = await api.get("/studio/analytics");
      console.log("Analytics Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Analytics fetch error:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch analytics"
      );
    }
  }
);

// Keep this for future timeframe-based analytics
export const fetchTimeframeAnalytics = createAsyncThunk(
  "analytics/fetchTimeframeAnalytics",
  async (timeframe = "28days", { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/studio/analytics/timeframe?period=${timeframe}`
      );
      return response.data;
    } catch (error) {
      console.error("Timeframe analytics fetch error:", error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch timeframe analytics"
      );
    }
  }
);

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    overview: {
      totalViews: 0,
      subscriberCount: 0,
      videoCount: 0,
      channelCreatedAt: null,
    },
    topVideos: [],
    timeframeData: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearAnalytics: (state) => {
      state.overview = {
        totalViews: 0,
        subscriberCount: 0,
        videoCount: 0,
        channelCreatedAt: null,
      };
      state.topVideos = [];
      state.timeframeData = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Channel Analytics
      .addCase(fetchChannelAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChannelAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.overview = action.payload.overview;
        state.topVideos = action.payload.topVideos;
      })
      .addCase(fetchChannelAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Timeframe Analytics
      .addCase(fetchTimeframeAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTimeframeAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.timeframeData = action.payload;
      })
      .addCase(fetchTimeframeAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearAnalytics } = analyticsSlice.actions;
export default analyticsSlice.reducer;
